import Alpine from 'alpinejs'
import whenDomReady from "when-dom-ready"
import ticketBookingForm from './TicketBooking/TicketBooking'

declare global {
    interface Window {
        Alpine: typeof Alpine;
        algoliaAppId: string;
        algoliaSearchOnlyApiKey: string;
    }
}
whenDomReady(async () => {
    window.Alpine = Alpine
    window.algoliaAppId = import.meta.env.VITE_ALGOLIA_APP_ID as string
    window.algoliaSearchOnlyApiKey = import.meta.env.VITE_ALGOLIA_SEARCH_ONLY_API_KEY as string
    ticketBookingForm()

    Alpine.start()
})

// Hot Module Logic
if ((import.meta as any).hot) {
    (import.meta as any).hot.on('vite:beforeFullReload', () => {
        throw '(skipping full reload)'
    })
}
